import React from 'react';
import SEO from '../components/core/meta/SEO';
import { styled } from 'twin.macro';
import Heading from '../components/core/typography/Heading';
import { Container } from '../components/core/layout/Layout';
import { ChevronRight } from '@material-ui/icons';
import { Link, graphql, useStaticQuery } from 'gatsby';

export const StyledGeneratedHtmlContainer = styled.div`
  table {
    td {
      padding: 0.5rem;
      border: 1px solid black;
    }
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
`;

const PrivacyAndCookiePolicyPage = () => {
  const brochureBannerData = useStaticQuery(graphql`
    query file {
      UGOPPC: file(relativePath: { eq: "UGO_PP_Caregiver.pdf" }) {
        publicURL
      }
      UGOPP: file(relativePath: { eq: "UGO_PP_Ugo.pdf" }) {
        publicURL
      }
      UGOCP: file(relativePath: { eq: "UGO_CP_lug_2024.pdf" }) {
        publicURL
      }
    }
  `);

  return (
    <div>
      <SEO title="Privacy e cookie policy" url="/privacy-e-cookie-policy" />
      <section tw="my-16">
        <Container>
          <Heading variant="h1" tw="text-primary-500 mb-0 text-5xl">
            PRIVACY e COOKIE POLICY
          </Heading>
          <p tw="mb-10">
            <em>relativa al sito internet </em>
            <a href="http://www.hellougo.com">
              <em>www.hellougo.com</em>
            </a>
            <em> </em>
          </p>
          <div tw="flex gap-8 flex-wrap w-full sm:w-auto justify-center ">
            <div>
              <a href={brochureBannerData.UGOPPC.publicURL} target="_blank">
                <div tw="rounded-card p-8 shadow-card bg-white">
                  Privacy policy Caregiver <ChevronRight />
                </div>
              </a>
            </div>
            <div>
              <a href={brochureBannerData.UGOPP.publicURL} target="_blank">
                <div tw="rounded-card p-8 shadow-card bg-white">
                  Privacy policy <ChevronRight />
                </div>
              </a>
            </div>
            <div>
              <a href={brochureBannerData.UGOCP.publicURL} target="_blank">
                <div tw="rounded-card p-8 shadow-card bg-white">
                  Cookie policy <ChevronRight />
                </div>
              </a>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default PrivacyAndCookiePolicyPage;
